
























































































































































import { Component, Watch, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'
import controller from '@/app/ui/controllers/ProgramaticPointController'
import { Utils } from '@/app/infrastructures/misc'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import { PROGRAMMATIC_POINT_USAGE_PAGINATION } from '@/app/infrastructures/misc/Constants'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import useProgrammaticPoint from '@/app/ui/views/Saldo/ProgramaticPoint/useCase'

interface Parameter {
  page: number
  perPage: number
  startDate: string | null
  endDate: string | null
  customerId: string | null
}

dayjs.extend(dayjsUTC)

@Component({
  components: {
    DateTimePicker,
    DataTable,
    DownloadIcon,
    PaginationNav,
    Button,
    TextInput,
    Loading,
    EmptyState,
  },
})
export default class UsagePoint extends Vue {
  controller = controller
  useActionProgrammaticPoint = useProgrammaticPoint()
  parameters: Parameter = {
    page: 1,
    perPage: PROGRAMMATIC_POINT_USAGE_PAGINATION,
    startDate: null,
    endDate: null,
    customerId: null,
  }
  headersTitle = [
    'Program Name',
    'User ID',
    'Account Name',
    'Email',
    'City of Residence',
    'Get Point Date',
    'Expired Date',
    'Saldo Point',
    'Spending Point',
  ]
  hasData = false

  created(): void {
    const queries = <Record<string, never>>this.$route.query
    this.parameters = {
      page: Utils.alwaysNumber(queries.page) || 1,
      perPage:
        Utils.alwaysNumber(queries.perPage) ||
        PROGRAMMATIC_POINT_USAGE_PAGINATION,
      startDate: queries.startDate,
      endDate: queries.endDate,
      customerId: queries.customerId || null,
    }

    controller.getProgramUsage({
      id: this.$route.params.id,
      params: this.params,
    })
  }

  get isParcelPoint(): boolean {
    return this.useActionProgrammaticPoint.isParcelPointCondition(
      controller.programDetail?.purpose,
      controller.programDetail?.triggerBy,
      controller.programDetail?.target
    )
  }

  get headers(): Array<string> {
    const newHeader = [
      'Program Name',
      'User ID',
      'Account Name',
      'Email',
      'City of Residence',
      'Get Point Date',
      'Parcel Poin',
    ]

    if (this.isParcelPoint) {
      return newHeader
    }

    return this.headersTitle
  }

  get programName(): string | undefined {
    return controller.programDetail.name
  }

  get params(): Record<string, string | number | null> {
    return {
      ...this.parameters,
      startDate: this.parameters.startDate
        ? dayjs(this.parameters.startDate, 'YYYY-MM-DD')
        .utc()
        .toISOString()
        : null,
      endDate: this.parameters.endDate
        ? dayjs(this.parameters.endDate, 'YYYY-MM-DD')
        .endOf('days')
        .utc()
        .toISOString()
        : null,
    }
  }

  get historyData(): Array<unknown>[] {
    const data: Array<Array<unknown>> = []
    if (controller.historyData.length > 0) {
      this.hasData = true
      // using FOR Loop for better looping performance
      for (let i = 0; i < controller.historyData.length; i++) {
        const historyValue = [
          controller.historyData[i].name,
          controller.historyData[i].userId,
          controller.historyData[i].userName,
          controller.historyData[i].userEmail,
          controller.historyData[i].city,
          dayjs(controller.historyData[i].disbursedAt).format('DD/MM/YYYY'),
          dayjs(controller.historyData[i].expiredDate).format('DD/MM/YYYY'),
          `Rp ${Utils.currencyDigit(
            controller.historyData[i].saldoPoint || 0
          )}`,
          `Rp ${Utils.currencyDigit(
            controller.historyData[i].pointSpent || 0
          )}`,
        ]
        if (this.isParcelPoint) {
          historyValue.splice(6, 1)
          historyValue.splice(historyValue.length - 1, 1)
        }
        data.push(historyValue)
      }
    } else {
      this.hasData = false
    }
    return data
  }

  @Watch('parameters', { deep: true })
  onParametersChanged(val: Record<string, never>): void {
    if (
      dayjs(val.endDate, 'YYYY-MM-DD').isBefore(
        dayjs(val.startDate, 'YYYY-MM-DD')
      ) ||
      !val.endDate
    ) {
      this.parameters.endDate = val.startDate
    }
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      query: {
        ...val,
        startDate: this.parameters.startDate
          ? dayjs(this.parameters.startDate).format('YYYY-MM-DD')
          : null,
        endDate: this.parameters.endDate
          ? dayjs(this.parameters.endDate).format('YYYY-MM-DD')
          : null,
      },
    })
  }

  private fetchHistory(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    const params = {
      id: this.$route.params.id,
      params: this.params,
    }

    if (controller.isParcelPointProgram) {
      controller.getProgramHistoryV2(params)
    } else {
      controller.getProgramHistory(params)
    }
  }

  private getExportUrl() {
    if (this.hasData) {
      const params = {
        id: this.$route.params.id,
        params: this.params,
      }
      if (controller.isParcelPointProgram) {
        controller.getExportedFileV2(params)
      } else {
        controller.getExportedFile(params)
      }
    }
  }
}
